import React, { useState, useEffect, useRef } from "react";
import {
  Brain,
  ChevronRight,
  Cpu,
  Network,
  Zap,
  Lock,
  Users,
  Globe,
} from "lucide-react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

export default function Home() {
  const [content, setContent] = useState("");
  const [cursorVisible, setCursorVisible] = useState(true);
  const [showOverlay, setShowOverlay] = useState(true);
  const terminalRef = useRef(null);

  useEffect(() => {
    const text = `
    
Welcome to Generally.ai

[SYSTEM] Initializing...

A new independent AI research organization focused in creating an AGI that generates income by completing tasks in the real world

We're offering a new token to participate in our research, development, and profit in the long term. 

`;

    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < text.length) {
        setContent((prev) => prev + text.charAt(i));
        i++;
        if (terminalRef.current) {
          terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
        }
      } else {
        clearInterval(typingInterval);
      }
    }, 10);

    const cursorInterval = setInterval(() => {
      setCursorVisible((prev) => !prev);
    }, 200);

    return () => {
      clearInterval(typingInterval);
      clearInterval(cursorInterval);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowOverlay(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container>
      {showOverlay && <Overlay />}
      <Header>
        <LogoWrapper>
          <Brain css={iconStyle} />
          <Title>generally.ai</Title>
        </LogoWrapper>
        <Nav>
          {/* <NavItem>About</NavItem> */}
          {/* <NavItem>Research</NavItem>
          <NavItem>Ethics</NavItem>
          <NavItem>Join Us</NavItem> */}
        </Nav>
      </Header>
      <Main>
        <TerminalWrapper>
          <TerminalHeader>
            <TerminalButton css={redButton} />
            <TerminalButton css={yellowButton} />
            <TerminalButton css={greenButton} />
          </TerminalHeader>
          <Terminal ref={terminalRef}>
            <pre css={preStyle}>
              {content}
              {cursorVisible && <Cursor>▋</Cursor>}
            </pre>
          </Terminal>
        </TerminalWrapper>
      </Main>
      <Sidebar>
        <SidebarItem>
          <Cpu css={sidebarIconStyle} />
          <span>Manifest</span>
        </SidebarItem>
        <SidebarItem>
          <Network css={sidebarIconStyle} />
          <span>Organization Structure</span>
        </SidebarItem>
        <SidebarItem>
          <Zap css={sidebarIconStyle} />
          <span>Commitments</span>
        </SidebarItem>
        <SidebarItem>
          <Lock css={sidebarIconStyle} />
          <span>Ethical Framework</span>
        </SidebarItem>
        <SidebarItem>
          <Users css={sidebarIconStyle} />
          <span>Human Collaboration</span>
        </SidebarItem>
        <SidebarItem>
          <Globe css={sidebarIconStyle} />
          <span>Open Source</span>
        </SidebarItem>
      </Sidebar>
      <Footer>
        <ChevronRight css={iconStyle} />
        <span>Scroll for more</span>
      </Footer>
    </Container>
  );
}

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(74, 222, 128, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(74, 222, 128, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(74, 222, 128, 0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1000;
  animation: ${fadeOut} 1s ease-out 2s forwards;
`;

const Container = styled.div`
  min-height: 100vh;
  background-color: #000300;
  color: #4ade80;
  font-family: "Courier New", monospace;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-template-rows: auto 1fr auto;
  gap: 1rem;
  overflow: hidden;
`;

const Header = styled.header`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  background-color: rgba(74, 222, 128, 0.1);
  border-radius: 0.375rem;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  background: linear-gradient(45deg, #4ade80, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Nav = styled.nav`
  display: flex;
  gap: 1rem;
`;

const NavItem = styled.a`
  color: #4ade80;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: #3b82f6;
  }
`;

const Main = styled.main`
  grid-column: 1 / 2;
  border: 1px solid #4ade80;
  border-radius: 0.375rem;
  overflow: hidden;
  background-color: rgba(74, 222, 128, 0.05);
`;

const TerminalWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const TerminalHeader = styled.div`
  background-color: #2d3748;
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
`;

const TerminalButton = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const redButton = `
  background-color: #fc8181;
`;

const yellowButton = `
  background-color: #f6e05e;
`;

const greenButton = `
  background-color: #68d391;
`;

const Terminal = styled.div`
  height: calc(100% - 2rem);

  padding: 1rem;
  background-color: #000300;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1a202c;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4ade80;
    border-radius: 4px;
  }
`;

const Sidebar = styled.aside`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: rgba(74, 222, 128, 0.05);
  border-radius: 0.375rem;
  border: 1px solid #4ade80;
`;

const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: rgba(74, 222, 128, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(74, 222, 128, 0.2);
    transform: translateX(5px);
  }
`;

const Footer = styled.footer`
  grid-column: 1 / -1;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  color: #4ade80;
  animation: ${pulseAnimation} 2s infinite;
`;

const Cursor = styled.span`
  animation: blink 1.06s steps(2, start) infinite;

  @keyframes blink {
    to {
      visibility: hidden;
    }
  }
`;

const iconStyle = `
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
`;

const sidebarIconStyle = `
  width: 1.5rem;
  height: 1.5rem;
  text-align: left;
`;

const preStyle = `
  white-space: pre-wrap;
  word-break: break-word;
`;
