// App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "@emotion/styled";
import "./App.css";
import Home from "./Home";

function App() {
  return (
    <StyledApp className="App">
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </StyledApp>
  );
}

export default App;

const StyledApp = styled.div`
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
